<template>
  <div>
    <b-card >
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/bobooxun-salimov.jpg"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Бобоохун Салимов <br />(1874-1929)</p></b-card-text
          >
        </b-col>
        <b-col md="6">
           <b-card-text>
          <p class="TextBlack17">
            Давлат ва сиёсат арбоби, маърифатпарвар Бобоохун Салимов 1874 йили
            Гурлан беклигида қозикалон Салимохун оиласида дунёга келди. Aсли
            исми Муҳаммад бўлиб, унга ҳурмат юзасидан Бобоохун деб тахаллус
            берилган. У бошланғич таълимни олгач, Хива шаҳридаги Оллоқулихон
            мадрасасида таҳсилни давом еттиради. Aраб, форс, рус тилларини
            ўрганади, шеърлар машқ қилади. 1910—1918 йилларда Хива хони
            Aсфандиёрхон саройида отаси ўрнида шайхулислом лавозимини адо етади.
            1917 йил Хивадаги инқилобий воқеаларида иштирок етиб, парламент
            сифатида ташкил етилган Мажлис аъзолигига сайланади. 1918—1920
            йилларда Тўрткўлда яшаб, "Ёш хиваликлар" партиясига аъзо бўлиб
            киради. 1920 йили халқ вакилларининг Бутунхоразм И қурултойида
            Хоразм Халқ Совет Республикасининг Aдлия нозири лавозимига
            сайланади. Кейинчалик у Хоразмдаги мактаб, маориф ишларида
            қатнашади. 1924 йилда Хоразм руҳонийларининг 1 қурултойи чақирилиши
            ва ўтказилишига раҳбарлик қилди. Б.Салимов ислом ғоялари асосида
            янгиликка интилишга, шариатда тенгликка еришишга, хон
            амалдорларининг ҳуқуқлари билан ўсиб келаётган савдогарлар ва
            саноатчилар, ҳунармандлар ва зиёлилар ҳуқуқларини тенглаштиришга
            интилди. Дастлаб у хонликда майда ҳуқуқий ва иқтисодий ислоҳотларни
            амалга ошириш билан тараққиётга еришиш мумкин, деб ҳисоблаган.
            Бобоохун Салимов 20-йиллар охирида коммунистик тоталитар тузум
            томонидан зиёлиларнинг таъқиб қилиниши натижасида давлат ишларидан
            четлаштирилади. Унинг қарашлари, мақсад ва ғоялари болшевистик
            сиёсатга зид еди. Шунинг учун у 1929 йил 10 май куни кечқурун совет
            ҳокимияти томонидан судсиз, сўроқсиз отиб ўлдирилади. Бобоохун
            Салимов қабрининг қаердалиги номаълум.
          </p>
        </b-card-text>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
       
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
          
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
